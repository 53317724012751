(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('profileCtrl', function($scope, $window, $state,$rootScope, $location,UserFactory,AuthTokenFactory,dealsService) {

      var vm = this;

      vm.user = AuthTokenFactory.getUser();

      vm.logout = function() {
        UserFactory.logout();
        vm.user = null;
        $state.go('login');
      }

  });
})();