(function() {

  'use strict';

  angular
    .module('singitaPartners')
    .controller('userCtrl', profileController);

  function profileController($http,AuthTokenFactory,sgUserService) {

    var vm = this;
    vm.getUsers = getUsers;
    vm.deleteUser = deleteUser;
    vm.users;

    vm.profile = AuthTokenFactory.getUser();

    function getUsers() {
      $http.get('/api/users').then(function(response) {

        vm.users = response.data;

      });
    }

    function deleteUser(id) {
      sgUserService.deleteUser(id).then(function(response){
        vm.users = response.users;
        alert("User deleted");
      })
    }

    vm.getUsers();

  }

})();