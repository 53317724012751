angular.module('singitaPartners')
  .factory('Users',function($resource) {
    var UserResource = $resource('/api/user/:id', {id: "@id"},{
      update: { method:'PUT', isArray:false }
    });

    UserResource.prototype.isAdmin = function() {
      return this.roles && this.roles.indexOf('admin') > -1;
    }

    return UserResource;

  });