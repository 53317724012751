(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('reportsAgencyCtrl', function($scope, $state, $stateParams, $window, $rootScope, $location,Agents,dealsService) {

      var vm = this;
      vm.getAgencyReport = getAgencyReport;
      vm.getAgentComm = getAgentComm;
      vm.getAgencyComm = getAgencyComm;
      vm.getTotalAgencyComm = getTotalAgencyComm;
      vm.getTotalAgentComm = getTotalAgentComm;


      vm.filter = {
        type: 'secured',
        start: $rootScope.start || moment().startOf('month').format(),
        end: $rootScope.end || moment().endOf('month').format()
      }

      vm.getAgencyReport();

      function getAgencyReport() {
        if (vm.filter.start) {
          $rootScope.start = vm.filter.start;
        }
        if (vm.filter.end) {
          $rootScope.end = vm.filter.end;
        }
        dealsService.getAgencyReport($stateParams.id,vm.filter).then(function(report){
          vm.secured = report.secured[0];
          vm.pending = report.pending[0];
        })
      }

      function getAgentComm(deal) {
        var total = 0;
        deal.agents.map(function(agent){
          total += agent.agentcomm;
        })

        return total;
      }

      function getAgencyComm(deal) {
        var total = 0;
        deal.agents.map(function(agent){
          total += agent.companycomm;
        })

        return total;
      }

      function getTotalAgencyComm(deals) {
        var total = 0;

        _.map(deals,function(deal){
          total += getAgencyComm(deal);
        })

        return total;

      }

      function getTotalAgentComm(deals) {
        var total = 0;

        _.map(deals,function(deal){
          total += getAgentComm(deal);
        })

        return total;

      }

  });
})();