"use strict";

angular.module('singitaPartners')
  .factory('agentsService', function($q,$rootScope,$window,Agents) {
    return {

      createAgent: function(agentData) {
        var dfd = $q.defer();

        var agent = new Agents();

        angular.extend(agent,agentData);

        agent.$save().then(function(response){
          dfd.resolve(response);
        },function(response) {
          dfd.reject(response.data.reason);
        });

        return dfd.promise;

      },
      updateAgent: function(agentsData) {
        var dfd = $q.defer();

        var agent = new Agents();

        angular.extend(agent,agentsData);

        agent.$update({id:agentsData._id}).then(function(response){
          dfd.resolve();
        },function(response) {
          dfd.reject(response.data.message);
        });
        return dfd.promise;
      },
      deleteAgent: function(agentData) {
        var dfd = $q.defer();

        var agent = new Agents();

        angular.extend(agent,agentData);

        agent.$delete({id:agent._id}).then(function(response){
          dfd.resolve(response);
        },function(response) {
          dfd.reject(response.data.reason);
        });

        return dfd.promise;

      },
    }
  });