(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('createDealCtrl', function($scope, $state, $window, $rootScope, $location,dealsService,AuthTokenFactory,Agency,Agents) {

      var vm = this;
      vm.createDeal = createDeal;
      vm.addAgent = addAgent;
      vm.addRoyaltyAgent = addRoyaltyAgent;
      vm.removeAgent = removeAgent;
      vm.removeRoyaltyAgent = removeRoyaltyAgent;
      vm.addBuyerSeller = addBuyerSeller;
      vm.removeBuyerSeller = removeBuyerSeller;


      vm.selectedAgent = {};
      vm.selectedRoyaltyAgent = {};

      vm.deal =  {
        calculate:false,
        purchase: 1000000,
        vat: 1.15,
        commission: 10,
        other: {
          split: 0,
          type:'percentage'
        },
        external: {
          split: 0,
          type:'percentage'
        },
        agents:[],
        agentsRoyalty: []
      };

      vm.selected = AuthTokenFactory.getAgency();

      if (vm.selected == null) {
        vm.showSelection = true;
        vm.setAgency = setAgency;

        Agency.query().$promise.then(function(data){
          vm.agencies = data;
        });

      } else {
        vm.deal.agency = vm.selected;
        Agents.query({agency:vm.selected}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });

      }

      function addAgent() {
        if (vm.selectedAgent._id) {
          vm.deal.agents.push(vm.selectedAgent);
          vm.selectedAgent = {};
        }
      }

      function removeAgent(agent) {
        var index = vm.deal.agents.map(function(e) { return e._id }).indexOf(agent._id);
        if (index > -1) {
          vm.deal.agents.splice(index,1);
        }
      }

      function addRoyaltyAgent() {
        if (vm.selectedRoyaltyAgent._id) {
          vm.deal.agentsRoyalty.push(vm.selectedRoyaltyAgent);
          vm.selectedRoyaltyAgent = {};
        }
      }

      function addBuyerSeller(type) {
        if (!vm.deal[type]) {
          vm.deal[type] = [];
        }

        vm.deal[type].push({
          'name': "",
          'surname': "",
          'identity': "",
          'office': "",
          'mobile': "",
          'email': ""
        });

      }

      function removeBuyerSeller(index,type) {
        vm.deal[type].splice(index,1);
      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        vm.deal.agency = agency._id;
        Agents.query({agency:agency._id}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });
      }

      function removeRoyaltyAgent(agent) {
        var index = vm.deal.agentsRoyalty.map(function(e) { return e._id }).indexOf(agent._id);
        if (index > -1) {
          vm.deal.agentsRoyalty.splice(index,1);
        }
      }


      function createDeal() {
        //vm.deal = dealsService.calculateDeal(vm.deal);

        dealsService.createDeal(vm.deal).then(function(data){
          swal({title: "Deal has been created",type:'success'},function(){
            $state.go('viewDeal',{id:data._id});
          })
        }, function(reason) {
          swal({title: "Error",text:"Error creating deal agent",type:'error'})
        })

      }

      function getQuarter(date) {
        var month = moment(date).month();
        var year = moment(date).year();
        var lastyear = moment(date).subtract(1,'year').year();

        var quarter = '';

        if (month <= 2) {
          quarter = '4-'+lastyear;;
        } else if (month <= 5) {
          quarter = '1-'+year;
        } else if (month <= 8) {
          quarter = '2-'+year;
        } else if (month <= 11) {
          quarter = '3-'+year;
        } else {
          quarter = '4-'+year;
        }

        return quarter;

      }

  });
})();