"use strict";

angular.module('singitaPartners')
  .factory('pcUserService', function($q,$rootScope,$window,Users) {
    return {

      createUser: function(newUserData) {
        var dfd = $q.defer();
        var user = new Users();
        angular.extend(user,newUserData);

        user.$save().then(function(response){
          dfd.resolve();
        },function(response) {
          dfd.reject(response.data.reason);
        });
        return dfd.promise;
      },
      deleteUser: function(id) {
          var dfd = $q.defer();
          var user = new Users({id:id});
          user.$delete().then(function(response){
            dfd.resolve(response);
          },function(response) {
            dfd.reject(response.data.message);
          });

          return dfd.promise;
      },
      updateUser: function(newUserData) {
        var dfd = $q.defer();

        var user = new Users();

        angular.extend(user,newUserData);

        user.$update({id:newUserData._id}).then(function(response){
          dfd.resolve();
        },function(response) {
          dfd.reject(response.data.message);
        });
        return dfd.promise;
      },
    }
  });