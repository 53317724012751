(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('reportsCtrl', function($scope, $window, $rootScope, $stateParams,$location,UserFactory,Agency,dealsService,AuthTokenFactory) {

      var vm = this;

      vm.type = $stateParams.type || null;


      UserFactory.checkRole("admin").then(function(){
        vm.national = true;

        vm.nationalReports = [{
          name: "Agency Report",
          description: "Sales reports for all agencies",
          type: "agency"
        },
        {
          name: "Agents Report",
          description: "Sales reports for all agents",
          type: "agents"
        },
        {
          name: "PCSA Royalty",
          description: "Property Cloud SA Royalty",
          type: "royalty"
        }];


      })

      vm.agencyReports = [{
          name: "Sales Report",
          description: "Sales report for agency",
          type: 'sales'
        },
        {
          name: "Agents Report",
          description: "Sales reports for all ",
          type: 'agents'
        }];

        vm.selected = AuthTokenFactory.getAgency();

        if (vm.selected == null) {
          vm.showSelection = true;
          vm.setAgency = setAgency;

          Agency.query().$promise.then(function(data){
            vm.agencies = data;
          });

        } else {
          vm.showSelection = false;
          vm.agency = vm.selected;
        }

        function setAgency(agency) {
          AuthTokenFactory.setAgency(agency._id);
          vm.agency = agency._id;
          vm.showSelection = false;
        }



  });
})();