(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('reportsAgentCtrl', function($scope, $state, $stateParams, $window, $rootScope, $location,Agents,dealsService) {

      var vm = this;
      vm.updateAgent = updateAgent;
      vm.getCommString = getCommString;
      vm.getAgentReport = getAgentReport;


      Agents.get({id:$stateParams.id}).$promise.then(function(data){
        vm.agent = data.agent;
      });

      vm.filter = {
        type: 'secured',
        start: $rootScope.start || moment().startOf('month').format(),
        end: $rootScope.end || moment().endOf('month').format()
      }

      vm.getAgentReport();

      function getAgentReport() {
        if (vm.filter.start) {
          $rootScope.start = vm.filter.start;
        }
        if (vm.filter.end) {
          $rootScope.end = vm.filter.end;
        }
        dealsService.getAgentReport($stateParams.id,vm.filter).then(function(report){
          vm.secured = report.secured[0];
          vm.profitshare = report.profitshare[0];
          vm.pending = report.pending[0];
        })

      }

      function getCommString() {
        switch (vm.agent.commission.type) {
          case 'manual': return "Set Commission"; break;
          case 'sales': return "Sales Based Commission"; break;
        }
      }

      function updateAgent() {
        agentsService.updateAgent(vm.agent).then(function(){
          alert('Agent has been updated');
        }, function(reason) {
          alert('Error archiving agent');
        })
      }



  });
})();