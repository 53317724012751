(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('viewDealsCtrl', function($scope, $state, $window, $rootScope, $location,agencyService,Deals,AuthTokenFactory,Agency) {

      var vm = this;

      vm.archiveDeal = archiveDeal;
      vm.selected = AuthTokenFactory.getAgency();

      if (vm.selected == null) {
        vm.showSelection = true;
        vm.setAgency = setAgency;

        Agency.query().$promise.then(function(data){
          vm.agencies = data;
        });

      } else {

        Deals.query({agency:vm.selected}).$promise.then(function(data){
          vm.deals = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });

      }

      function archiveDeal(id) {

        agencyService.archiveAgent(id).then(function(){
          alert('Agent has been archived');
        }, function(reason) {
          alert('Error archiving agent');
        })

      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        Deals.query({agency:agency._id}).$promise.then(function(data){
          vm.deals = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });
      }

  });
})();