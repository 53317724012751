"use strict";

angular.module('singitaPartners', [
  'ui.router',
  'ngMessages',
  'ngResource',
  'ngSanitize',
  'ui.select',
  'daAPIconfig',
  'angular-loading-bar',
  'ngAnimate',
  'ui.bootstrap',
  'my.templates'
  ])
  .config(function ($stateProvider, $urlRouterProvider,endpoint,$locationProvider,$httpProvider) {

    $locationProvider.html5Mode(true).hashPrefix('!');

    $urlRouterProvider.otherwise("/");

    var routeRoleChecks = {
      admin: {
        auth: function(UserFactory) {
          return UserFactory.checkRole('admin')
        }
      }
    }

    $stateProvider

      .state('dashboard', {
        url: '/',
        templateUrl: 'views/admin.html',
        controller: "homeCtrl as vm",
        authenticate: true,
      })

      .state('login', {
        url: '/login',
        templateUrl: 'views/login.html',
        controller: "authCtrl as vm",
      })

      .state('agency', {
        url: '/agency/',
        templateUrl: 'views/agency/viewAgency.html',
        controller: "agencyCtrl as vm",
        authenticate: true,
      })

      .state('adminAgencyAdd', {
        url: '/admin/agency/add',
        templateUrl: 'views/agency/addAgency.html',
        controller: "addAgencyCtrl as vm",
        authenticate: true,
        resolve: routeRoleChecks.admin
      })

      .state('agents', {
        url: '/agency/agents',
        templateUrl: 'views/agents/viewAgents.html',
        controller: "viewAgentsCtrl as vm",
        authenticate: true,
      })

      .state('agentAdd', {
        url: '/agency/agents/',
        templateUrl: 'views/agents/addAgent.html',
        controller: "addAgentCtrl as vm",
        authenticate: true,
      })

      .state('agent', {
        url: '/agency/agents/:id',
        templateUrl: 'views/agents/editAgent.html',
        controller: "editAgentCtrl as vm",
        authenticate: true,
        switch: 'agents'
      })

      .state('deals', {
        url: '/agency/deals',
        templateUrl: 'views/deals/viewDeals.html',
        controller: "viewDealsCtrl as vm",
        authenticate: true,
      })

      .state('createDeal', {
        url: '/agency/deals/create',
        templateUrl: 'views/deals/createDeal.html',
        controller: "createDealCtrl as vm",
        authenticate: true,
      })

      .state('editDeal', {
        url: '/agency/deals/edit/:id',
        templateUrl: 'views/deals/editDeal.html',
        controller: "editDealCtrl as vm",
        authenticate: true,
        switch: 'deals'
      })

      .state('viewDeal', {
        url: '/agency/deals/:id',
        templateUrl: 'views/deals/viewDeal.html',
        controller: "viewDealCtrl as vm",
        authenticate: true,
        switch: 'deals'
      })

      .state('reportsDashboard', {
        url: '/reports',
        templateUrl: 'views/reports/dashboard.html',
        controller: "reportsCtrl as vm",
        authenticate: true,
      })

      .state('reportsNational', {
        url: '/reports/national/:type',
        templateUrl: 'views/reports/national.html',
        controller: "reportsCtrl as vm",
        authenticate: true,
      })

      .state('reportsAgent', {
        url: '/reports/agent/:id',
        templateUrl: 'views/reports/agent.html',
        controller: "reportsAgentCtrl as vm",
        authenticate: true,
        switch: 'reportsDashboard'
      })

      .state('reportsAgency', {
        url: '/reports/agency/:type/:id',
        templateUrl: 'views/reports/agencyContainer.html',
        controller: "reportsCtrl as vm",
        authenticate: true,
        switch: 'reportsDashboard'
      })

      .state('adminUsers', {
        url: '/admin/users',
        templateUrl: 'views/admin/admin.html',
        controller: "adminUsersCtrl as vm",
        authenticate: true,
      })

      .state('editUser', {
        url: '/admin/edit/:id',
        templateUrl: 'views/admin/editAdmin.html',
        controller: "editUserCtrl as vm",
        authenticate: true,
      })

      .state('newUser', {
        url: '/admin/add',
        templateUrl: 'views/new-user.html',
        controller: "newUserCtrl as vm",
        authenticate: true,
      })

      $httpProvider.interceptors.push(function($q,AuthTokenFactory,$location) {
        return {
          request: function(config) {
            var token = AuthTokenFactory.getToken();
            if (token) {
              config.headers = config.headers || {};
              config.headers.Authorization = 'Bearer ' + token;
            }
            return config;
          },
          responseError: function(rejection) {
            if (rejection.status === 401) {
              AuthTokenFactory.setUser();
              AuthTokenFactory.setToken();
              $location.path('/login')
            }
            return $q.reject(rejection);
          }
        };
      });



  })
  .run(function($rootScope, $state, $window,$log,$injector,AuthTokenFactory) {

    $rootScope.$on("$stateChangeError", function (evt, toState, toParams, fromState, fromParams, rejection) {
      console.log(rejection);
      console.log(event);

    });



    $rootScope.$on("$stateChangeStart", function(event, toState, toParams, fromState, fromParams){
      $log.log('Enter stateChangeStartListener toState', toState, "fromState", fromState);

      if (!toState) {
        // TODO: Is this actually possible?
        $log.error('entered stateChangeStartListener without toState');
        return;
      }

      if (!event) {
        // TODO: when is this possible and what can we do?
        $log.error('entered stateChangeStartListener without event');
        return;
      }

      if (toState.authenticate && AuthTokenFactory.getToken() == null){
        $state.transitionTo("login");
        event.preventDefault();
        return;
      }

    });

    $rootScope.$on('$stateChangeSuccess', function() {
      document.body.scrollTop = document.documentElement.scrollTop = 0;
    });

  });