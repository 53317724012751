(function () {
  "use strict";
  angular.module('singitaPartners')
    .controller('authCtrl', function(UserFactory,$state,$rootScope,AuthTokenFactory) {
      'use strict';
      var vm = this;

      vm.login = login;
      vm.logout = logout;
      vm.loading = false;
      vm.error = "";

      vm.showLogin = 1;
      vm.showForgot = 0;

      function login(username, password) {
        vm.loading = true;
        vm.error = "";
        UserFactory.login(username, password).then(function success(response) {
          UserFactory.getUserInfo().then(function(resp){
              vm.loading = false;
              $rootScope.user = resp;
              $rootScope.currentAgency = AuthTokenFactory.getAgency;
              if (!$rootScope.currentAgency) {
                  $rootScope.currentAgency = $rootScope.user.agencies[0]._id;
              }
              $state.go('dashboard', {}, { location: 'replace' });
          });

        }, handleError);
      }

      function logout() {
        UserFactory.logout();
        vm.user = null;
        $state.go('home', {}, { location: 'replace' });
      }

      function handleError(response) {
        vm.loading = false;
        vm.error = response.data.message;
      }

    });
})();
