(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('adminUsersCtrl', function($scope, $state, $window, $rootScope, $location,Users) {

      var vm = this;

      Users.query().$promise.then(function(data){
        vm.users = data;
      });

  });
})();