(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('nationalAgentCtrl', function($scope, $window, $rootScope, $location,singitaService,UserFactory,Agency,dealsService) {

      var vm = this;
      vm.getSales = getSales;

      vm.filter = {
        type: 'secured',
        start: $rootScope.start || moment().startOf('month').format(),
        end: $rootScope.end || moment().endOf('month').format()
      }

      vm.getSales();

      vm.sorterFunc = function(agent){
        if (agent.sales[0] && agent.sales[0].totalWeighted > 0) {
          return parseInt(agent.sales[0].totalWeighted );
        } else {
          return 0;
        }
      };


      function getSales() {
        if (vm.filter.start) {
          $rootScope.start = vm.filter.start;
        }
        if (vm.filter.end) {
          $rootScope.end = vm.filter.end;
        }

        dealsService.getAgentsSales(vm.filter).then(function(agents){
          vm.agents = agents;
        })
      }

  });
})();