(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('editAgentCtrl', function($scope, $state, $stateParams, $window, $rootScope, $location,Agents,dealsService,agentsService) {

      var vm = this;
      vm.updateAgent = updateAgent;
      vm.getCommString = getCommString;
      vm.deleteAgent = deleteAgent;

      Agents.get({id:$stateParams.id}).$promise.then(function(data){
        vm.agent = data.agent;
        vm.deals = data.deals;

        dealsService.getAgentSale($stateParams.id,'registered').then(function(sales){
          vm.sales = sales;
          console.log(sales);
        })

      });

      function getCommString() {
        if (vm.agent) {
          switch (vm.agent.commission.type) {
            case 'manual': return "Set Commission"; break;
            case 'sales': return "Performance Based Commission"; break;
          }
        }
      }

      function updateAgent() {
        agentsService.updateAgent(vm.agent).then(function(){
          swal({title: "Agent has been updated",type:'success'})
        }, function(reason) {
          swal({title: "Error",text:reason,type:'error'})
        })
      }

      function deleteAgent() {
        agentsService.deleteAgent(vm.agent).then(function(data){
          swal({title: "Agent has been deleted",type:'success'},function(){
            $state.go('agents');
          })
        }, function(reason) {
          swal({title: "Error",text:"Error deleting agent",type:'error'})
        })
      }



  });
})();