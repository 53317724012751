(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('addAgencyCtrl', function($scope, $state, $window, $rootScope, $location,agencyService,Users) {

      var vm = this;
      vm.addAgency = addAgency;
      vm.agency = {
        users:[]
      };

      Users.query().$promise.then(function(data){
        vm.users = data;
      })

      function addAgency() {

        agencyService.createAgency(vm.agency).then(function(data){
          swal({title: "Agency Created",type:'success'})
          vm.agency = {
            users:[]
          };
        }, function(err) {
          swal({title: "Error",text:err,type:'error'})
        })

      }

  });
})();