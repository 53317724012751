(function () {
	"use strict";

	angular.module('singitaPartners')
	  .controller('headCtrl', function($scope, $state, $window, $rootScope, $location,singitaService,AuthTokenFactory,UserFactory) {


	  	$scope.isAuthed = function() {
	  		return AuthTokenFactory.isAuthed();
	  	}

	  	$scope.isAdmin = function() {
	  		var user = AuthTokenFactory.getUser();
	  		if (user.roles.indexOf("ADMIN") > -1) {
	  			return true
	  		} else {
	  			return false;
	  		}
	  	}

	  	$scope.loggout = function() {
	  		UserFactory.logout();
	  		$state.go("home");
	  	}

	    singitaService.getTaxonomy("type")
	      .then(
	      function(payload) {
	        $rootScope.types = payload;
	      },
	      function(errorPayload) {
	        alert("Nooo");
	      });


	});
})();