angular.module('singitaPartners')
  .factory('AuthTokenFactory', function AuthTokenFactory($window) {
    'use strict';
    var store = $window.localStorage;
    var key = 'auth-token';
    var userKey = 'auth-user';

    return {
      getToken: getToken,
      setToken: setToken,
      getUser: getUser,
      setUser: setUser,
      isAuthed: isAuthed,
      setAgency: setAgency,
      getAgency: getAgency,
    };

    function getToken() {
      return store.getItem(key);
    }

    function getUser() {
      return JSON.parse(store.getItem(userKey));
    }

    function getAgency() {
      return store.getItem("agency");
    }

    function isAuthed() {
      var token = store.getItem(key);
      if(token) {
        var params = parseJwt(token);
        return Math.round(new Date().getTime() / 1000) <= params.exp;
      } else {
        return false;
      }
    }

    function parseJwt(token) {
      var base64Url = token.split('.')[1];
      var base64 = base64Url.replace('-', '+').replace('_', '/');
      return JSON.parse($window.atob(base64));
    }

    function setToken(token) {
      if (token) {
        store.setItem(key, token);
      } else {
        store.removeItem(key);
      }
    }

    function setAgency(agency) {
      if (agency) {
        store.setItem("agency", agency);
      } else {
        store.removeItem("agency");
      }
    }

    function setUser(user) {
      if (user) {
        store.setItem(userKey, JSON.stringify(user));
      } else {
        store.removeItem(userKey);
      }
    }

  });