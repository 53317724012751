(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('editUserCtrl', function($scope, $state, $stateParams,singitaService,AuthTokenFactory,pcUserService,Users,Agency) {

      Users.get({id:$stateParams.id}).$promise.then(function(response){
        $scope.user = response;
      },handleError)



      $scope.roles = ["admin","agency","agent"]

      Agency.query().$promise.then(function(data){
          $scope.agencies = data;
      });

      $scope.updateUser = function() {
        var newUserData = angular.copy($scope.user);
        if ($scope.password && $scope.password.length > 0) {
          newUserData.password = $scope.password;
        }
        pcUserService.updateUser(newUserData).then(function(){
          alert('User account has been updated');
        }, function(reason) {
          alert('Reject');
        })
      }

      function handleError(response) {
        alert('Error: ' + response.data.message);
        $state.go("home");
      }



  });
})();