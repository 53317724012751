(function () {
	"use strict";

	angular.module('singitaPartners')
	  .controller('homeCtrl', function($scope, $state, $window, $rootScope, $location,singitaService,AuthTokenFactory,Agency,dealsService) {

	  	var vm = this;


	  	vm.agency = "";
      vm.getSales = getSales;
      vm.getAgency = getAgency;

	    vm.selected = AuthTokenFactory.getAgency();

      if (vm.selected == null) {
        vm.showSelection = true;
        vm.setAgency = setAgency;

        Agency.query().$promise.then(function(data){
          vm.agencies = data;
        });

      } else {
        vm.agency = vm.selected;
        vm.showSelection = false;
        vm.getSales();
        vm.getAgency();
      }


      function getSales() {
        dealsService.getSales('secured',vm.agency,'month').then(function(sales){
          vm.sales = sales;
        })

        dealsService.getSales('pending',vm.agency,null).then(function(sales){
          vm.pending = sales;
        })

        dealsService.getSales('registered',vm.agency,"year").then(function(sales){
          vm.salesYear = sales;
        })
      }

      function getAgency(id) {
        Agency.get({id:vm.agency}).$promise.then(function(data){
          vm.deals = data.deals;
          vm.agents = data.agents;
        });
      }

      vm.getCommString = getCommString;
      function getCommString(type) {
        switch (type) {
          case 'manual': return "Set Commission"; break;
          case 'sales': return "Sales Based Commission"; break;
          default:
            return "Set Commission";
        }
      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        vm.agency = agency._id;
        vm.showSelection = false;
        vm.getSales();
        vm.getAgency();
      }


	});
})();