"use strict";

angular.module('singitaPartners')
  .factory('dealsService', function($q,$rootScope,$window,Deals,$http) {
    return {

      createDeal: function(dealData) {
        var dfd = $q.defer();

        var deal = new Deals();

        angular.extend(deal,dealData);

        deal.$save().then(function(response){
          dfd.resolve(response);
        },function(response) {
          dfd.reject(response.data.reason);
        });

        return dfd.promise;

      },
      deleteDeal: function(dealData) {
        var dfd = $q.defer();

        var deal = new Deals();

        angular.extend(deal,dealData);

        deal.$delete({id:deal._id}).then(function(response){
          dfd.resolve(response);
        },function(response) {
          dfd.reject(response.data.reason);
        });

        return dfd.promise;

      },
      updateDeal: function(dealData) {
        var dfd = $q.defer();

        var deal = new Deals();

        angular.extend(deal,dealData);

        deal.$update({id:deal._id}).then(function(response){
          dfd.resolve(response);
        },function(response) {
          dfd.reject(response.data.message);
        });
        return dfd.promise;
      },
      getSales: function(type,agency,report) {

        var dfd = $q.defer();

        $http.get('/api/sales',
          {
            params:{
              agency:agency,type:type,report:report
            },
            cache: true
          })
          .then(function (response) {
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getAgentSale: function(id,type) {
        console.log("!@3132312132");
        var dfd = $q.defer();

        $http.get('/api/sales/agent/'+id,
          {
            params:{
              agency:$rootScope.agency,type:type
            },
            cache: true
          })
          .then(function (response) {
            console.log(response);
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getAgentReport: function(id,query) {
        var dfd = $q.defer();

        $http.get('/api/sales/agent/report/'+id,
          {
            params:query,
            cache: true
          })
          .then(function (response) {
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getAgencyReport: function(id,query) {
        var dfd = $q.defer();

        $http.get('/api/sales/agency/report/'+id,
          {
            params:query,
            cache: true
          })
          .then(function (response) {
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getAgenciesSales: function(query) {
        var dfd = $q.defer();

        $http.get('/api/sales/agencies',
          {
            params:query,
            cache: true
          })
          .then(function (response) {
            console.log(response);
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getAgentsSales: function(query) {
        var dfd = $q.defer();

        $http.get('/api/sales/agents',
          {
            params:query,
            cache: true
          })
          .then(function (response) {
            console.log(response);
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      getRoyaltySales: function(query) {
        var dfd = $q.defer();

        $http.get('/api/sales/royalty/report',
          {
            params:query,
            cache: true
          })
          .then(function (response) {
            console.log(response);
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },
      calculateDeal: function(deal) {
        deal.calculate = true;

        if (deal.grosscommType == 'percentage') {
          if (deal.other.type == 'percentage') {
            deal.grosscomm = (deal.purchase * deal.commission/100) * (1-(deal.other.split/100));
          } else {
            deal.grosscomm = (deal.purchase * deal.commission/100) - deal.other.split;
          }
        } else {
          if (deal.other.type == 'percentage') {
            deal.grosscomm = deal.grosscommAmount * (1-(deal.other.split/100));
          } else {
            deal.grosscomm = deal.grosscommAmount - deal.other.split;
          }
        }

        deal.nettcomm = deal.grosscomm / deal.vat;
        deal.royalty = deal.nettcomm * (6.5/100);
        deal.referral = (deal.nettcomm - deal.royalty) * 0;

        if (deal.agentsRoyalty.length > 0) {
          deal.profitshare = deal.royalty * (16.67/100);
          _.map(deal.agentsRoyalty,function(agent){
            agent.profitshare = deal.profitshare/deal.agentsRoyalty.length;
          })
        } else {
          deal.profitshare = 0;
        }

        deal.brandroyalty = deal.royalty - deal.profitshare;

        _.map(deal.agents,function(agent){

          agent.purchase = deal.purchase * (agent.comm/100);

          if (deal.grosscommType == 'percentage') {
            if (deal.other.type == 'percentage') {
              agent.grosscomm = (agent.purchase * (deal.commission/100))*(1-(deal.other.split/100));
            } else {
              agent.grosscomm = (agent.purchase * (deal.commission/100)) - (deal.other.split * (agent.comm/100));
            }
          } else {
            if (deal.other.type == 'percentage') {
              agent.grosscomm = (deal.grosscommAmount*agent.comm/100)*(1-(deal.other.split/100));
            } else {
              agent.grosscomm = (deal.grosscommAmount*agent.comm/100) - (deal.other.split * (agent.comm/100));
            }
          }



          agent.nettcomm = agent.grosscomm / deal.vat;
          agent.weighted = agent.grosscomm / (6.84/100);
          agent.royalty = agent.nettcomm * (6.5/100);
          agent.referral = (agent.nettcomm - agent.royalty) * 0;

          agent.companycomm = (agent.nettcomm - agent.royalty - agent.referral)*(1-(agent.commission.percentage_agent/100));
          agent.agentcomm = (agent.nettcomm - agent.royalty - agent.referral)*((agent.commission.percentage_agent/100));

          return agent;
        })

        if (deal.status == 'secured') {

          var month = moment(deal.secured).month();
          var year = moment(deal.secured).year();
          var lastyear = moment(deal.secured).subtract(1,'year').year();

          var quarter = '';

          if (month <= 2) {
            quarter = '4-'+lastyear;;
          } else if (month <= 5) {
            quarter = '1-'+year;
          } else if (month <= 8) {
            quarter = '2-'+year;
          } else if (month <= 11) {
            quarter = '3-'+year;
          } else {
            quarter = '4-'+year;
          }

          deal.quarter = quarter;
        }

        return deal;
      }
    }
  });