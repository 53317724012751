(function() {

  'use strict';

  angular
    .module('singitaPartners')
    .controller('profileController', profileController);

  function profileController($http,AuthTokenFactory) {

    var vm = this;
    vm.getUserDocuments = getUserDocuments;
    vm.documents;

    vm.profile = AuthTokenFactory.getUser();

    function getUserDocuments() {
      $http.get('/api/documents').then(function(response) {
        vm.documents = response.data;
      });
    }

    vm.getUserDocuments();

  }

})();