(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('editDealCtrl', function($scope, $state, $stateParams,$window, $rootScope, $location,dealsService,AuthTokenFactory,Agency,Deals,Agents) {

      var vm = this;
      vm.updateDeal = updateDeal;
      vm.addAgent = addAgent;
      vm.addRoyaltyAgent = addRoyaltyAgent;
      vm.removeAgent = removeAgent;
      vm.removeRoyaltyAgent = removeRoyaltyAgent;
      vm.selectedAgent = {};
      vm.selectedRoyaltyAgent = {};
      vm.addBuyerSeller = addBuyerSeller;
      vm.removeBuyerSeller = removeBuyerSeller;


      vm.selected = AuthTokenFactory.getAgency();

      if (vm.selected == null) {
        vm.showSelection = true;
        vm.setAgency = setAgency;

        Agency.query().$promise.then(function(data){
          vm.agencies = data;
        });

      } else {

        Deals.get({id:$stateParams.id,agency:vm.selected}).$promise.then(function(data){
          vm.deal = data;
          if (!vm.deal.hasOwnProperty('external')) {
            vm.deal.external = {
              split: 0,
              type:'percentage'
            }
          }
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });

        Agents.query({agency:vm.selected}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });


      }

      function addAgent() {
        if (vm.selectedAgent._id) {
          vm.deal.agents.push(vm.selectedAgent);
          vm.selectedAgent = {};
        }
      }

      function removeAgent(agent) {
        var index = vm.deal.agents.map(function(e) { return e._id }).indexOf(agent._id);
        if (index > -1) {
          vm.deal.agents.splice(index,1);
        }
      }

      function addBuyerSeller(type) {
        if (!vm.deal[type]) {
          vm.deal[type] = [];
        }

        vm.deal[type].push({
          'name': "",
          'surname': "",
          'identity': "",
          'office': "",
          'mobile': "",
          'email': ""
        });

      }

      function removeBuyerSeller(index,type) {
        vm.deal[type].splice(index,1);
      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        Deals.get({id:$stateParams.id,agency:agency._id}).$promise.then(function(data){
          vm.deal = data;
        });
        Agents.query({agency:agency._id}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });
      }

      function addRoyaltyAgent() {
        if (vm.selectedRoyaltyAgent._id) {
          vm.deal.agentsRoyalty.push(vm.selectedRoyaltyAgent);
          vm.selectedRoyaltyAgent = {};
        }
      }

      function removeRoyaltyAgent(agent) {
        var index = vm.deal.agentsRoyalty.map(function(e) { return e._id }).indexOf(agent._id);
        if (index > -1) {
          vm.deal.agentsRoyalty.splice(index,1);
        }
      }

      function updateDeal() {

        //vm.deal = dealsService.calculateDeal(vm.deal);

        dealsService.updateDeal(vm.deal).then(function(data){
          swal({title: "Deal has been created",type:'success'},function(){
            $state.go('viewDeal',{id:data._id});
          })
        }, function(reason) {
          swal({title: "Error",text:"Error updating deal",type:'error'})
        })

      }

      function getQuarter(date) {
        var month = moment(date).month();
        var year = moment(date).year();
        var lastyear = moment(date).subtract(1,'year').year();

        var quarter = '';

        if (month <= 1) {
          quarter = '4-'+lastyear;
        } else if (month <= 4) {
          quarter = '1-'+year;
        } else if (month <= 7) {
          quarter = '2-'+year;
        } else if (month <= 10) {
          quarter = '3-'+year;
        } else {
          quarter = '4-'+year;
        }

        return quarter;

      }

  });
})();