angular.module('singitaPartners')
	.factory('singitaService',function($http,$q,$stateParams,endpoint) {
		return {

			getTaxonomy: function(type) {

				var dfd = $q.defer();

				$http.get(endpoint.wordpress+type,{params:{per_page:20},cache: true})
					.then(function (response) {
					    dfd.resolve(response.data);
					}, function (response) {
					    dfd.reject(response.data.reason);
					});

				return dfd.promise;
			},
			getTaxonomyID: function(type,id) {

				var dfd = $q.defer();

				$http.get(endpoint.wordpress+type+'/'+id,{cache: true})
					.then(function (response) {
					    dfd.resolve(response.data);
					}, function (response) {
					    dfd.reject(response.data.reason);
					});

				return dfd.promise;
			},
			filterResults: function(filter,search,page) {

				var dfd = $q.defer();

				var query = {
    				"engine_key":endpoint.swiftype_engine,
    				"page":page,
    				"q":"",
    				"per_page":9,
    				"sort_field": {"posts": "title"},
    				"filters":{
      					"posts":{
      						"object_type": ["attachment","videos"],
      						"lodge":[],
      						"type":[]
      					}
    				},
    				"facets":{
    					"posts":["type","lodge"]
    				}
   				};

   				if (search != "") {
   					query.q = search;
   				}

   				console.log(filter);

				filter.map(function(item){
					query.filters.posts[item.taxonomy].push(item.id);
				});


				$http({
					method: 'POST',
					url: endpoint.swiftype,
					auth: {
						user: endpoint.swiftype_key
					},
					cache: true,
					data: query,
					headers: {'Content-Type': 'application/json'}
				})
				.then(function (response) {
					dfd.resolve(response.data);
				}, function (response) {
				    dfd.reject(response.data.reason);
				})

				return dfd.promise;

			},
	    readItem: function (type,id) {

	    	var dfd = $q.defer();

	      	var objectName = type+'/'+id;
	      	$http({
	        	method: 'GET',
	        	url: endpoint.wordpress + objectName,
	        	cache: true
			}).then(function (response) {
				dfd.resolve(response.data);
			});

			return dfd.promise;
	    },
      getGroups: function() {

        var dfd = $q.defer();

        $http.get('/api/groups')
          .then(function (response) {
              dfd.resolve(response.data);
          }, function (response) {
              dfd.reject(response.data.reason);
          });

        return dfd.promise;
      },

		};
	});