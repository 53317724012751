angular.module('singitaPartners')
  .factory('UserFactory', function UserFactory($http, AuthTokenFactory, $q) {
    'use strict';

    return {
      login: login,
      logout: logout,
      checkRole: checkRole,
      getUserInfo: getUserInfo
    };

    function login(username, password) {
      return $http.post('/api/user/authenticate', {
        username: username,
        password: password
      }).then(function success(response) {
        AuthTokenFactory.setToken(response.data.token);

        return response;
      });
    }

    function logout() {
      AuthTokenFactory.setToken();
      AuthTokenFactory.setUser();
    }


    function getUserInfo() {
      var dfd = $q.defer();

      $http.get('/api/user/me').then(function(response){
        var user = response.data;
        AuthTokenFactory.setUser(user);
        dfd.resolve(user);

      },function() {
        dfd.reject("not authorized");
      });

      return dfd.promise;
    }

    function checkRole(role) {

      var dfd = $q.defer();

      $http.get('/api/user/me').then(function(response){
        var user = response.data;

        if (user.roles.indexOf(role) > -1 ) {
          dfd.resolve(true);
        } else {
          dfd.reject("not authorized");
        }

      },function() {
        dfd.reject("not authorized");
      });

      return dfd.promise;

    }

  });