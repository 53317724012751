(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('agencyCtrl', function($scope, $state, $window, $rootScope, $location,agencyService,Agency,AuthTokenFactory,UserFactory) {

      var vm = this;
      vm.updateAgency = updateAgency;
      vm.showSelection = false;
      vm.selected = AuthTokenFactory.getAgency();
      vm.getCommString = getCommString;

      UserFactory.checkRole("admin").then(function(){
        vm.admin = true;
      });


      Agency.get({id:vm.selected}).$promise.then(function(data){
        vm.agency = data.agency;
        vm.deals = data.deals;
        vm.agents = data.agents;
        vm.showSelection = false;
      },function(err){
        vm.showSelection = true;
      });


      function getCommString(type) {
        switch (type) {
          case 'manual': return "Set Commission"; break;
          case 'sales': return "Sales Based Commission"; break;
          default:
            return "Set Commission";
        }
      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        vm.agency = agency;
        vm.showSelection = false;
      }



      function updateAgency() {

        agencyService.updateAgency(vm.agency).then(function(){
          swal({title: "User account has been updated",type:'success'})
        }, function(reason) {
          swal({title: "Error",text:reason,type:'error'})
        })

      }

  });
})();