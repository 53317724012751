(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('SearchCtrl', function($scope, $window, $rootScope, $location,singitaService,$sce) {

    var scope = $rootScope;

    $scope.filters = [];
    $scope.results = [];
    $scope.info = {};
    $scope.mode = "grid";
    $scope.query = "";
    $scope.currentPage = 1;

    if ($rootScope.type) {

    }

    $scope.videoSrc = function(item) {
      return $sce.trustAsResourceUrl(item);
    }


    $scope.setClass = function(mode) {
      $scope.mode = mode;
    }


    $scope.addFilter = function(item) {
        var i = $scope.filters.indexOf(item, $scope.filters);

        if (i > -1) {
            $scope.filters.splice(i, 1);
        } else {
            $scope.filters.push(item);
        }
    }

    $scope.search = function() {
      singitaService.filterResults($scope.filters,$scope.query,$scope.currentPage).then(function(results) {
        $scope.info = results.info.posts;
        $scope.facets = results.info.posts.facets;
        $scope.currentPage = results.info.posts.current_page;
        $scope.results = results.records.posts;
        

      },function(error) {
        alert("Nooo");
      })
    }

    $scope.search();

    $scope.filter = function() {
      $scope.currentPage = 1;
      $scope.search();
    }

    $scope.nextPage = function() {
      $scope.currentPage++;
      $scope.search();
      document.body.scrollTop = document.documentElement.scrollTop = 400;
    }

    $scope.prevPage = function() {
      $scope.currentPage--;
      $scope.search();
      document.body.scrollTop = document.documentElement.scrollTop = 400;
    }

    singitaService.getTaxonomy("lodge")
      .then(
      function(payload) { 
        $scope.lodges = payload;
      },
      function(errorPayload) {
        alert("Nooo");
      });

    singitaService.getTaxonomy("type")
      .then(
      function(payload) { 
        $rootScope.types = payload;
      },
      function(errorPayload) {
        alert("Nooo");
      });
      

  });
})();