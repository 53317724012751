(function () {
    "use strict";

    angular.module('singitaPartners')
    .controller('mainCtrl', function($timeout, $state,$stateParams, $scope,UserFactory,$rootScope,AuthTokenFactory,Agency){

        // Detact Mobile Browser
        if( /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) ) {
           angular.element('html').addClass('ismobile');
        }

        // By default Sidbars are hidden in boxed layout and in wide layout only the right sidebar is hidden.
        this.sidebarToggle = {
            left: false,
            right: false
        }

        // By default template has a boxed layout
        this.layoutType = localStorage.getItem('ma-layout-status');

        // For Mainmenu Active Class
        this.$state = $state;

        //Close sidebar on click
        this.sidebarStat = function(event) {
            if (!angular.element(event.target).parent().hasClass('active')) {
                this.sidebarToggle.left = false;
            }
        }

        //Listview Search (Check listview pages)
        this.listviewSearchStat = false;

        this.lvSearch = function() {
            this.listviewSearchStat = true;
        }

        //Listview menu toggle in small screens
        this.lvMenuStat = false;

        //Blog
        this.wallCommenting = [];

        this.wallImage = false;
        this.wallVideo = false;
        this.wallLink = false;

        //Skin Switch
        this.currentSkin = 'blue';

        this.skinList = [
            'lightblue',
            'bluegray',
            'cyan',
            'teal',
            'green',
            'orange',
            'blue',
            'purple'
        ]

        this.skinSwitch = function (color) {
            this.currentSkin = color;
        }

        UserFactory.getUserInfo().then(function(resp){
            $rootScope.user = resp;
            $rootScope.currentAgency = AuthTokenFactory.getAgency;
            if (!$rootScope.currentAgency) {
                $rootScope.currentAgency = $rootScope.user.agencies[0]._id;
            }



        });

        this.setAgency = function(agency) {
            $rootScope.currentAgency = agency;
            AuthTokenFactory.setAgency(agency);
            if ($state.current.switch) {
                $state.transitionTo($state.current.switch, $stateParams, {
                  reload: true, inherit: false, notify: true
                });
            } else {
                $state.transitionTo($state.current, $stateParams, {
                  reload: true, inherit: false, notify: true
                });
            }
        }


    });
})();