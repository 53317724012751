"use strict";

angular.module('singitaPartners')
  .factory('agencyService', function($q,$rootScope,$window,Agency) {
    return {

      createAgency: function(agencyData) {

        var dfd = $q.defer();
        var agency = new Agency();

        angular.extend(agency,agencyData);

        agency.$save().then(function(response){
          dfd.resolve();
        },function(response) {
          dfd.reject(response.data.reason);
        });

        return dfd.promise;

      },
      deleteAgency: function(id) {
          var dfd = $q.defer();

          var agency = new Agency({id:id});

          agency.$delete().then(function(response){
            dfd.resolve(response);
          },function(response) {
            dfd.reject(response.data.message);
          });

          return dfd.promise;
      },
      updateAgency: function(agencyData) {
        var dfd = $q.defer();

        var agency = new Agency();

        angular.extend(agency,agencyData);

        agency.$update({id:agencyData._id}).then(function(response){
          dfd.resolve();
        },function(response) {
          dfd.reject(response.data.message);
        });
        return dfd.promise;
      },
    }
  });