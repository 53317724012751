(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('addAgentCtrl', function($scope, $state, $window, $rootScope, $location,agentsService,Agency,AuthTokenFactory) {

      var vm = this;
      vm.addAgent = addAgent;
      vm.agent = {};

      vm.selected = AuthTokenFactory.getAgency();

      Agency.get({id:vm.selected}).$promise.then(function(data){
        vm.agent.agency = data.agency;
      },function(err){
        $rootScope.showSelection = true;
      });

      function addAgent() {

        agentsService.createAgent(vm.agent).then(function(data){
          console.log(data);
          swal({title: "Agent Added",type:'success'},function(){
            $state.go('agent',{id:data._id});
          })

        }, function(reason) {
          swal({title: "Error",text:reason,type:'error'})
        })

      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        vm.agent.agency = agency;
        vm.showSelection = false;
      }

  });
})();