(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('viewAgentsCtrl', function($scope, $state, $window, $rootScope, $location,agencyService,Agents,AuthTokenFactory,Agency) {

      var vm = this;
      vm.archiveAgent = archiveAgent;

      vm.selected = AuthTokenFactory.getAgency();

      if (vm.selected == null) {
        vm.showSelection = true;
        vm.setAgency = setAgency;

        Agency.query().$promise.then(function(data){
          vm.agencies = data;
        });

      } else {

        Agents.query({agency:vm.selected}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });

      }

      function archiveAgent(id) {

        agencyService.archiveAgent(id).then(function(){
          swal({title: "Agent has been archived",type:'success'})
        }, function(reason) {
          swal({title: "Error",text:"Error archiving agent",type:'error'})
        })

      }

      function setAgency(agency) {
        AuthTokenFactory.setAgency(agency._id);
        Agents.query({agency:agency._id}).$promise.then(function(data){
          vm.agents = data;
          vm.showSelection = false;
        },function(err){
          vm.showSelection = true;
        });
      }

  });
})();