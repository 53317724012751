(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('newUserCtrl', function($scope, $state, $window, $rootScope, $location,singitaService,AuthTokenFactory,pcUserService,Agency) {

      $scope.user = {
        agencies:[],
        roles:[]
      }

      Agency.query().$promise.then(function(data){
          $scope.agencies = data;
      });

      $scope.roles = ["admin","agency","agent"]
      $scope.isAuthed = function() {
        return AuthTokenFactory.isAuthed();
      }

      $scope.selected = [];

      singitaService.getGroups().then(function(response){
        $scope.groups = response;
      })

      $scope.createUser = function() {
        var newUserData = angular.copy($scope.user);
        if ($scope.password && $scope.password.length > 0) {
          newUserData.password = $scope.password;
        }

        pcUserService.createUser(newUserData).then(function(){
          $scope.user = {
            agencies:[],
            roles:[]
          }
          alert('User account has been created');
        }, function(reason) {
          alert('Reject');
        })
      }



  });
})();