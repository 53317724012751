(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('viewDealCtrl', function($scope, $state,$stateParams, $window, $rootScope, $location,dealsService,Deals,AuthTokenFactory) {

    var vm = this;
    vm.quickSave = quickSave;
    vm.deleteDeal = deleteDeal;

    Deals.get({id:$stateParams.id}).$promise.then(function(data){
      vm.deal = data;
      var vat = vm.deal.vat || 1.14;

      if (vat === 1.15) {
        vm.vat = '15%';
      } else {
        vm.vat = '14%';
      }

      vm.download = '/api/deals/'+$stateParams.id+'?export=true&token='+AuthTokenFactory.getToken();
    },function(err){
      alert(err);
    });


    function quickSave() {

      dealsService.updateDeal(vm.deal).then(function(data){
        vm.deal = data;
        swal({title: "Deal has been created",type:'success'});
      }, function(reason) {
        swal({title: "Error",text:"Error updating deal",type:'error'})
      })
    }

    function deleteDeal() {
      dealsService.deleteDeal(vm.deal).then(function(data){
        swal({title: "Deal has been deleted",type:'success'},function(){
          $state.go('deals');
        })
      }, function(reason) {
        swal({title: "Error",text:"Error deleting deal",type:'error'})
      })
    }

  });
})();