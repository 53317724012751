(function () {
  "use strict";

  angular.module('singitaPartners')
    .controller('nationalRoyaltyCtrl', function($scope, $window, $rootScope, $location,singitaService,UserFactory,Agency,dealsService) {

      var vm = this;
      vm.getSales = getSales;

      vm.filter = {
        type: 'secured',
        start: $rootScope.start || moment().startOf('month').format(),
        end: $rootScope.end || moment().endOf('month').format()
      }

      vm.getSales();


      function getSales() {
        if (vm.filter.start) {
          $rootScope.start = vm.filter.start;
        }
        if (vm.filter.end) {
          $rootScope.end = vm.filter.end;
        }
        dealsService.getRoyaltySales(vm.filter).then(function(results){

          vm.deals = results[0];

        })
      }

  });
})();